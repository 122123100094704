import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Components",
  "description": "Selecting the appropriate components for your circuits.",
  "author": "Nadya Lukman",
  "categories": ["electronics"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Impedance", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Since components are essentialy what make a circuit, it's extremely important to choose your components carefully, to ensure your circuit is to work according to what it aims. After selecting these components, the choice of your components and their peripheral information should be carefully documented in a BOM for your PCB manufacturing and assembly process to begin.`}</p>
    <h2 {...{
      "id": "passive-components"
    }}>{`Passive Components`}</h2>
    <p>{`Passive components are components that influence the flow of electricity running through them. They store energy and produce inductance, but don't necessarily amplify or control the signals themselves.`}</p>
    <p>{`Passive components in circuits are commonly used to store and dissipate energy. They are also commonly used as transducers, which are responsible to convert electrical energy into another type of energy, or vice versa. Passive components are the main components that affect `}<a parentName="p" {...{
        "href": "/articles/Impedance/"
      }}>{`Impedance`}</a>{`, especially resistors, capacitors, and inductors, each of which has two other parasitic impedances besides intrinsic impedance.`}</p>
    <h3 {...{
      "id": "resistors"
    }}>{`Resistors`}</h3>
    <p>{`Resistors are one of the basic electrical components. For PCBs, they are provided in 2 types: `}<strong parentName="p">{`lead resistors`}</strong>{`, which are used for THT wave soldering, and `}<strong parentName="p">{`chip resistors`}</strong>{`, which are suitable for SMT reflow soldering.`}</p>
    <p>{`In lower frequencies, resistors contribute to the circuit's resistance only.`}</p>
    <p>{`In higher frequencies, resistors will also contribute to the circuit's impedance through parasitic capacitance. In the region of these high frequencies, the total impedance will decrease, but the resistor will act as a capacitor.`}</p>
    <p>{`When the operating frequency is higher, the resistor will alter, and act as an inductor -- i.e., contributing to the circuit inductance, and hence increasing the general circuit impedance.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "494px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "85.71428571428571%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0ElEQVQ4y5VT266qMBT0/3/JBzXxwQuoKBCuy4jWQoBSKSC3055IjWfvrcnJnqdm0ZlZzFodXa/X5XJZVZX4PUZFUWiaVpblr2ic8wdZCEEIqeuac/5ngPzwX+aTnKZp27Yf70mtH6Jt22KMOecjzrnnedPpdDKZzGaz8XgcBMFX+Xf4vk8IeThXVWUYRhAEGGOEEMbYGdD3fdd1aEAURQghSqkQAgDiOJbqH9ouyzJN02CA53mmadq2fTgcCCEY4yiKvgWWJEnTNK8SY0zmhzFOkuRVj+PYcZy6rsuyrAd8I7+c7/e7PFNKAUD2AgC+76uquhug6/oHclVVdV2/DPu+BwDXdT8syXvbL2c5JCFEURRfK/LaM7B3Z7lwfICU+7i/T/LpdFIUZb/fAwBjjFLq+/5ms9F1/XK55HlOKT0ej/qALMv+OVuWpSiK67qO49i2vVqtFEUBAMMwLMsCAFVVF4sFANi2bZomAMjRPshZlsVxTCnNsizPc4zx+XxOkoQQQinN8zyKojAMGWNZljHGNE2T+Y1+rHue577vy/EKIZqmud1uUg4hxBiT+XVd9yTLYGSwCKHdbrfdbuX2hmEo/3O9Xs/nc8/z5GuRgf0FMnnFVnFUALYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/4a12ffa31137d59f77b2bf5dd5d31719/652f5/components-01.webp 259w", "/static/4a12ffa31137d59f77b2bf5dd5d31719/32f69/components-01.webp 494w"],
            "sizes": "(max-width: 494px) 100vw, 494px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/4a12ffa31137d59f77b2bf5dd5d31719/a2ead/components-01.png 259w", "/static/4a12ffa31137d59f77b2bf5dd5d31719/d72d4/components-01.png 494w"],
            "sizes": "(max-width: 494px) 100vw, 494px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/4a12ffa31137d59f77b2bf5dd5d31719/d72d4/components-01.png",
            "alt": "Resistor Impedance",
            "title": "Resistor Impedance",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "capacitors"
    }}>{`Capacitors`}</h3>
    <p>{`Commonly in PCBs, there are 2 types of capacitors used, based on their material: `}<strong parentName="p">{`film and electrolytic capacitors`}</strong>{`. The two are used for different applications since they have different material characteristics.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Film`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Electrolytic`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lifetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Several decades`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short life span`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Capacitance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relatively large, for large voltages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Larger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Polarity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No Polarity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has a cathode and anode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tolerance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±10% or even less than ±0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±20%`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The basic working principle of a capacitor is to store energy, so it can be used as an energy storage unit, but the amount of energy stored is directly related to the size of the capacitor. Generally, leaded tantalum capacitors with high capacitance for THT assemblies are used for this purpose.`}</p>
    <p>{`Although capacitors are used to store energy, the simplified equivalent circuit of a capacitor is a series connection of a resistor and an inductor. Hence, the capacitor has inductive reactance and will appear inductive on a certain frequency value.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "332px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.52895752895753%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABr0lEQVQoz1VS246jMAzl//9oH0e76uNq1RY2pbSFTmZKy0AuJIHc7BXQrpijKEpsH8c+cYKIn59fMQIgIsC8ECFAtPNhMU+7cxAjrpEg4m7bns/um3kij2tD28Ysc3MStNaN4/gk9z2/XDxJjciJvFbiUsprKcuCn878dBlo9X7s0sx7D0uir7bN89x5P5EZ44hAqc+2EsPoh9GPJljlzIB+qKne/VHPgma61qqua2vtROachzB1U11DWYU5KABYRFQKdqmHV/MLmubxN8tGOyZaa8YYwCIKpqmVEjGMVnaIuN9bKSOuuLKXTdNopQZjkvx4vN/vaZoSQvKc3G6cHNBr5VldP7AqI2PN8VicTgWlFAAopYwxSml+OCRKaSGE1toY45xHhEsZ38+9480uDc5NFc9eHePSEQghhmF4qi2lVFqfy2opLADuf3e7n6fbYwqem2z6ftLMGLPdbsuyzLKsKIpFbRZCGK39Pw8flf7142M1Hi6E57OEkLe3t81mwxibyF3XccaklOKFjrUtazjny3XtEkLAS71kyRdWiDEeDiTP8xi/2Rc4515/Dv8AisWs7997DfEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ce347482f9d599937267aa4af401f230/652f5/components-02.webp 259w", "/static/ce347482f9d599937267aa4af401f230/b76f7/components-02.webp 332w"],
            "sizes": "(max-width: 332px) 100vw, 332px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ce347482f9d599937267aa4af401f230/a2ead/components-02.png 259w", "/static/ce347482f9d599937267aa4af401f230/f8dc5/components-02.png 332w"],
            "sizes": "(max-width: 332px) 100vw, 332px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/ce347482f9d599937267aa4af401f230/f8dc5/components-02.png",
            "alt": "Frequency Characteristics of Capacitors",
            "title": "Frequency Characteristics of Capacitors",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "inductors"
    }}>{`Inductors`}</h3>
    <p>{`Inductors, like capacitors, are components that store energy. However, unlike capacitors which store energy in electric charges, inductors store transient energy as magnetic fields.`}</p>
    <p>{`As the stacks and coils of inductors are similar to having multiple capacitors in series, inductors also have capacitance.`}</p>
    <p>{`The frequency characteristics of an inductor is represented in an inverted V shape. When operating in low frequencies, it shows an inductive characteristic. However, when the operating frequency is higher than the resonant frequency, it becomes capacitive, which then lowers the general impedance of the circuit.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "710px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.15444015444015%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA9klEQVQY021R2Y7CMAzk/7+QVYuoxNLbNGkOJ46dolIoW2nnJbbjGU/i0/JCjDHnvMV5jfZsEeFvcsRpO5xzOb97YPB9i7toXd8PunvfTvbei6ylEJffy9hUyvm1rpQ+n3+MMX/J38nMjIjbNaKMsHg9p4gjiLUyTXC9VkSELyilAEBr7f2qfSKiaZqMmY3hpgnC4pvat13OAsBF0dxftnPOjwdUVXW73cqyCCGs5M3FPGPbhq7rQkBXXlxRRsSub+vaxrjsnpn5/w+z1gJAJJqds4iUaBhGrRUippR2wuHNn30IUSJKzNz33aw1M6c3SEQ+1MOqnqIT0X/cAJkpAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/466ee5ea1da8eb7ce55a241f511fbf43/652f5/components-03.webp 259w", "/static/466ee5ea1da8eb7ce55a241f511fbf43/c29d2/components-03.webp 518w", "/static/466ee5ea1da8eb7ce55a241f511fbf43/457aa/components-03.webp 710w"],
            "sizes": "(max-width: 710px) 100vw, 710px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/466ee5ea1da8eb7ce55a241f511fbf43/a2ead/components-03.png 259w", "/static/466ee5ea1da8eb7ce55a241f511fbf43/6b9fd/components-03.png 518w", "/static/466ee5ea1da8eb7ce55a241f511fbf43/7131f/components-03.png 710w"],
            "sizes": "(max-width: 710px) 100vw, 710px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/466ee5ea1da8eb7ce55a241f511fbf43/7131f/components-03.png",
            "alt": "Frequency Characteristics of Inductors",
            "title": "Frequency Characteristics of Inductors",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "active-components"
    }}>{`Active Components`}</h2>
    <p>{`Active components are electrical components with internal energy, which could generate, amplify or modify electrical signals. They are dependent on power sources to operate.`}</p>
    <p>{`Active components are generally semiconductors, which include diodes, transistors, thyristors, integrated circuits, etc.`}</p>
    <h3 {...{
      "id": "diodes"
    }}>{`Diodes`}</h3>
    <p>{`Diodes are 2-layered P and N ionized plates which conduct current only in one direction. There are a few commonly used diodes for PCB applications:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Rectifier diode - used to convert AC signals into DC signals.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Zener diode - used for voltage regulation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Schottky diode - used as rectifiers in high-frequency circuits.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Transient voltage suppression (TVS) diode - used to protect circuits from voltage spikes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Varactor diode - functions as a variable capacitor (different internal capacitance with different backward voltages)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avalanche diode - used for RF and microwave generation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PIN diode - used as RF switch and also for detecting X-rays and gamma rays.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Gunn diode - used for oscillators and microwave amplifiers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Laser diode - used in optical devices; such as CD, DVD, and Blu-ray recorders and players.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Light Emitting Diode (LED) - used in displays, as indicators and for lighting.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Photodiode - converts light signals into electrical signals.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tunnel diode - used as a very high-speed switch (in the nanosecond range).`}</p>
      </li>
    </ul>
    <p>{`Since a diode has polarity, where it only allows current to flow through from its anode to its cathode, it must be positioned correctly in a circuit to ensure it serves its function. When a diode's polarity is reversed, and it's constantly in reverse bias -- this could cause the diode to break down and damage the circuit.`}</p>
    <p>{`To indicate the polarity, you should first `}<strong parentName="p">{`silkscreen print the diode schematic symbol between the 2 pads`}</strong>{`. Another symbol, `}<strong parentName="p">{`a silkscreen thread should be added on the cathode side of the diode, to indicate the polarity.`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.79536679536679%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAC4jAAAuIwF4pT92AAADSklEQVQ4yzWTS1NbBRiGv5NCERFr24Ubxy50KjOOnU5V1DLQXCDJOblfSJpwmoSQhNCiJCVAkgEKJCmEi1wKdFKYMoIO0y5w2lU37PQf+B+c8U88SqmLZ95v8z7v6pN7tTD3HwxwPxUiWR2kbTKC+Pq4WLSg/J5AdnTa+7u5tJ1Bci4UiwOxupE+F9LnROxuFM2LonpQXAHkq5UIg8d59OUU6sEAhl0fEnYhDivityNhDxLwIiY7YnQglnf0OlF6nYhFQ6zncuXsloyGYSPM+9UIMqIhURXFoaEYnSh3HEiPinKGSUPM7zCp5xjtiFlF6XWcy0w2xGB1IGfFLg2l6yxVpFtFeuxvZRf7nBjM/5c1xOLEYPPS4uynzT/A5cggTc4QYnbw1iV3NAxGOwaLlSabnVani1a/i9aYjw+zQdruhXnPq3M5HOeTeIbrQxluDGcxT5UIzs8RKhWITIzwgWo7H5RoLxI3IYnbXJryY98u09eYwPZbBfevVXobk7gLY3hTSUZnRhmv5ak8KVB7OkFjf5zXzxb459Uwm7O3kFu3EWXNzRd7aVIHZaYPikxvl6nslWjUf6RejDBX0xkaG8FhsuKwqHRrZp4//pLHxQ7q0z+wX83x51aSv1+7ueH6Bml6EaOjnmD2dIO/jlb4Y+kBtUg3o74e6r5OFiLf4YgG8MR1QnoMX9iHmjZhL+t0lZKkVvKUNifZOZgjXU0gcuzjykmau4fTnL5a4/jJFLlCltDMI1LVebJrq2iLZZx7syQbVXLPV4gfzpF9WaH4osLSyRqLJ1vMvFxE280jUg1gyPloD7vQD9fI/Vwnv7NKdqPG+M4SC/vL5J8u8/12nkdvVimd1hh5U8Tzy0M614f5rDLA1aqfC1t2mqtO5ONwnCsxnZaED9fCFOtHe8w8WydYLxHdmCe6UUNdm+fqZIbmySgyFUBGg0j6LhLXET2GpMLISAAZDCItYx7aZvv5dDPDzb083zYecm08Tkd5iGs/DXE9X+DzsQLt8TQS0DEEkxiCgxhCSS6EkxhCCZT+M+Io/TFEVDPNu1G+Dti4eZTjo80wEujkYsCIYrYiRg0xqojFhWL3IzYvYvOco3oR1YM4/Ig7iPz3y/8CJbT/dyb/zlMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c4ce8176186efb82801efed098e9c7d1/652f5/components-06.webp 259w", "/static/c4ce8176186efb82801efed098e9c7d1/c29d2/components-06.webp 518w", "/static/c4ce8176186efb82801efed098e9c7d1/bc514/components-06.webp 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c4ce8176186efb82801efed098e9c7d1/a2ead/components-06.png 259w", "/static/c4ce8176186efb82801efed098e9c7d1/6b9fd/components-06.png 518w", "/static/c4ce8176186efb82801efed098e9c7d1/37523/components-06.png 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c4ce8176186efb82801efed098e9c7d1/37523/components-06.png",
            "alt": "Silkscreen Polarity Markings",
            "title": "Silkscreen Polarity Markings",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "transistors"
    }}>{`Transistors`}</h3>
    <p>{`Transistors are the basis of many integrated circuits and are used for error detection, rectification, amplification, switching, voltage stabilization, signal modulation, logic operations, etc. in PCBs.`}</p>
    <p>{`There are several types of transistors commonly used in circuit applications:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`BJT (Bipolar Junction Transistors)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`IGBT (Insulated-Gate Bipolar Transistor)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`JFET (Junction-Gate Field-Effect Transistor)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MOSFET (Metal Oxide Semiconductor Field-Effect Transistor)`}</p>
      </li>
    </ul>
    <p>{`There are 2 main package types of transistors,`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`TO series for THT soldering. This includes TO03, TO099, TO-263, TO214, etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SOT series for SMD soldering. This includes SOT23-5, SOT223, etc.`}</p>
      </li>
    </ul>
    <p>{`During operation, transistors often heat up. Therefore, they require heat sinks for their thermal management. Different types of heat sinks: metal case with fins, thermal grease, or any specially designed heat sink will work`}</p>
    <p>{`For SMDs (Surface Mount Devices), a large area of ​​copper should be laid in the body part, preferably connected to the GND plane, functioning as a thermal pad for heat dissipation. For THT components, it is generally possible to parallel several components on the same physical heat sink.`}</p>
    <h3 {...{
      "id": "thyristors"
    }}>{`Thyristors`}</h3>
    <p>{`Thyristors are 4 layered semiconductors with alternating P and N materials. They are commonly used to control the flow of current, acting as a conditional switch.`}</p>
    <p>{`The alternating 4 P and N layers provide thyristors with 3 junctions, with alternating polarity. To enable the flow of current from the anode to the cathode or vice versa, the polarity of the middle junction must be triggered by a signal. The middle junction is also known as the gate of the thyristor.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "247px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.2753036437247%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABbElEQVQoz31Sy2rCUBC93+YXhPYTBHfdF/wAN6VIV9JSF123SqNbUWkeJESjoM1CEK2ICuID8RHzOOVeekOMSQcumXtmcu7MmSEA4Pv+1aHmed4VlpTLfcIBDoaDzPe8q3j4HiUl3HFdl4H061IS38f3YMCw/W6H9XrN/MPhANu2g/zoY4xwuVyiWq1CURRIkhQkPWQyeMvlYE0m+BmNGLZYLKCqKur1OsrlMjRNu2yZXs7nM2azGURRRKVSgSJJ0Pp9vAgC3gnB/c0tPkQRbcNArVZDqVRCt9vFfD7HarWK15C2oOs6e/2r2YTc6eBZEPBJCO5SKRSKRbRbLdaBLMuYTqex2pI4sbk9ptN4zWbRG4/xlM9js9lcxPkWxFbIB0OP8ye42euBztixbViWhUajwXDHcRhZdCOCCuMsujbH4xHD4TCoLMlIHFFYV7Y2+z1M04RhGNhut/hPpsQKwz+dTic2MNoy38ckwl/jMT1wJnf8XAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/65656fd59bc18164ed23964b4211ba83/83176/components-04.webp 247w"],
            "sizes": "(max-width: 247px) 100vw, 247px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/65656fd59bc18164ed23964b4211ba83/fb3c7/components-04.png 247w"],
            "sizes": "(max-width: 247px) 100vw, 247px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/65656fd59bc18164ed23964b4211ba83/fb3c7/components-04.png",
            "alt": "Thyristor Symbol",
            "title": "Thyristor Symbol",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Thyristors have 3 methods of operations.`}</p>
    <ul>
      <li parentName="ul">{`Forward blocking state`}</li>
    </ul>
    <p>{`This occurs when the current is not flowing from the anode to the cathode, even though the potential difference between the two is positive. This is due to the low signal (or untriggered) gate channel.`}</p>
    <ul>
      <li parentName="ul">{`Reverse blocking state`}</li>
    </ul>
    <p>{`This occurs when the thyristor 'blocks' the current flow since the potential difference is negative between the anode and the cathode.`}</p>
    <ul>
      <li parentName="ul">{`Forward conducting state`}</li>
    </ul>
    <p>{`The state allows the flow of current from the thyristor's anode to its cathode, which only occurs when the gate channel has been triggered.`}</p>
    <p>{`There are few most commonly used thyristors in PCBs:`}</p>
    <ul>
      <li parentName="ul">{`SCR (Silicon controlled rectifier)`}</li>
    </ul>
    <p>{`SCR is also known as thyristor rectifier. The SCR is the basic structure of a thyristor, which has 3 channels: anode, cathode, and gate. When the gate receives enough signal, the SCR will become conductive and allow current flow in a direction.`}</p>
    <ul>
      <li parentName="ul">{`TRIAC (Triode for alternating current)`}</li>
    </ul>
    <p>{`TRIAC provides control of both halves of the alternating current, hence power is used more efficiently. However, due to its non-symmetrical configuration, TRIACs are more commonly used in lower power applications, such as light dimmers, electric fan speed controls, and small motor controllers.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "378px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.84555984555985%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACFElEQVQoz21TTU8TURSdn+DChW5M3Bh36tbEjQuDCxfuWGkifiQqoaOlBQWRFgqdaTudDlVRUkjTIp2hw0yr1qqLIpoaYottKcYPwk855N1hhmnp4mbum3fuued+PM6QtrEWa5ExXxXqUIUa9FgLRfk37PtunP3tvufIkSxgIb4DwfMWvpsSFL+J6OM8NHHLAeux5hHi3oQuwMrsT0T4HJYC66SWJbOJ3it/O4h6ErrJWOAH5R8WAxUsTHxB+cUestNVh0jy5jtI3NahsNv0aAOSV0c6uIHU8woC91M4e/o8Ll24ilJy11HcSyl3mKVJ5u7T3IiJ6YcZ9PcNguM4XDx3hQjzkcYB3h1r+VwuXIPgUSE+0iDyGvmW5SD7i7hxzYfrlwcwOqCgv++BheVVCPwBjtcQ9uSwOLkOM94G9y7xB6+ffcbYnVc0CHa2J1qa+4/48BqpO37sBJ7eTjrlsWC2AeN332Di3gI0sU7/qYfLM5tIPikhPfXtcEWiTXxM7mJ2aBmnTp7B6K0E4sO6E2gPIjVZgTJSJDyVzLLIXgMJfwGyz8RScAOm1CaFDJCe+o5sqIryyz2shDcpkBRKbcyPlSmOEc6Pf0I+2rAUsiaviltYjfzqWCHJaxBQ8RcwM5hBKvgVBXnH9apqyIZ+IBOqku9M+cgKsIlJ25SAGStTFeukwJBaMGJWyayP7HUxYz4j3AdmoblOa/4ljgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e1fcb1a70071944f12c45cfd8b8dd585/652f5/components-05.webp 259w", "/static/e1fcb1a70071944f12c45cfd8b8dd585/d6ea2/components-05.webp 378w"],
            "sizes": "(max-width: 378px) 100vw, 378px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e1fcb1a70071944f12c45cfd8b8dd585/a2ead/components-05.png 259w", "/static/e1fcb1a70071944f12c45cfd8b8dd585/f0991/components-05.png 378w"],
            "sizes": "(max-width: 378px) 100vw, 378px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e1fcb1a70071944f12c45cfd8b8dd585/f0991/components-05.png",
            "alt": "TRIAC Symbol",
            "title": "TRIAC Symbol",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`DIAC (Diode for alternating current)`}</li>
    </ul>
    <p>{`DIAC is used in series with the gate port of TRIAC, to ensure TRIAC is triggered evenly in both directions.`}</p>
    <ul>
      <li parentName="ul">{`SIDAC (Silicon diode for alternating current)`}</li>
    </ul>
    <p>{`SIDAC has a higher power-handling capability and higher breakover voltage than DIACs, and are used also to control the trigger of TRIACs. SIDAC is often found in relaxation oscillators or special-purpose devices.`}</p>
    <h3 {...{
      "id": "diodes-vs-transistors-vs-thyristors"
    }}>{`Diodes vs Transistors vs Thyristors`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Diode`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Transistor`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Thyristor`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Layers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 layers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 layers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 layers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gate Triggers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Turns on instantaneously when triggered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remains on or off when triggered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Turn-off-Circuit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Special Features`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cheaper `}<br />{` Less Bulky `}<br />{` Lower operating voltage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lower voltage drop than thyristor`}<br />{` Operates on linear applications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Excellent power handling `}<br />{` Low internal losses`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "batteries"
    }}>{`Batteries`}</h3>
    <p>{`Active components, by definition, are components with internal energy which could generate, amplify, or modify electrical signals. Batteries are ones that could generate electrical energy.0`}</p>
    <p>{`In NexPCB, we provide different types of batteries, which could be separated into 2 based on their life span,`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Disposable batteries, such as Zinc Manganese batteries, Disposable Lithium batteries, Button batteries.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Rechargeable batteries, such as NICAD, Ni-MH, Li-ion batteries, Lithium polymer batteries.`}</p>
      </li>
    </ol>
    <p>{`When shipping batteries, a few precautions must be taken into account, including`}</p>
    <ul>
      <li parentName="ul">{`Insulating and wrapping wires to prevent short-circuit between battery electrodes.`}</li>
      <li parentName="ul">{`Adding a layer of insulation in between batteries to avoid short-circuit.`}</li>
      <li parentName="ul">{`Adding shock-absorbing materials and other protective measurements to deal with movement and collisions during the transport process.`}</li>
      <li parentName="ul">{`Provide sturdy outer packaging to avoid squeezing of the product.`}</li>
    </ul>
    <h3 {...{
      "id": "integrated-circuits"
    }}>{`Integrated Circuits`}</h3>
    <p>{`An IC or Integrated Circuit is a semiconductor-based chip that supports numerous tiny components (resistors, transistors, capacitors). ICs are often embedded into circuit boards to allow complex operations without designing circuits from scratch each time, providing a more compact and cost-efficient solution.`}</p>
    <p>{`Microprocessor is a type of integrated circuit. Even though people tend to use the word microprocessors and CPUs interchangeably, both are 2 different types of components. In general, we can say the `}<strong parentName="p">{`CPU is a part of the computer which receives and sends commands to control its operation, and the microprocessor is an integrated circuit which is made up of CPU or other control units, along with clocks, ALU (Arithmetical and Logical Unit), Memory, etc.`}</strong></p>
    <p>{`Hence, when a CPU is designed to include memory, ALU, interrupts, input/output interfaces, it can also be called a microprocessor. However, the term microprocessors doesn't just fit CPUs -- other processing units such as GPUs, NPUs, and APUs are also microprocessors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      